<template>
  <v-row>
    <v-row>
      <v-col class="col-md-9 pr-md-1 sm-6">
        <h1 class="font-weight-light mb-0">General Settings</h1>
        <span class="overline">Add other dashboard views</span>
      </v-col>

      <v-col class="col-md-3 pr-md-1 fill-width">
        <v-btn @click="registerCompany" class="primary">New Company</v-btn>
      </v-col>
    </v-row>

    <v-col cols="12" class="py-0">
      <v-row>
        <v-col lg="5" cols="md" class="pb-2">
          <v-card-title class="primary--text">
            <span class="overline">Active Company</span>
          </v-card-title>

          <v-card v-if="!getFreelancerCompany.id" class="mx-auto text-center">
            <ActiveCompanyLoader></ActiveCompanyLoader>
          </v-card>

          <v-card v-else class="mx-auto text-center">
            <v-row class="pa-3">
              <v-col offset="1" cols="10" class="center relative">
                <v-avatar size="160">
                  <v-img
                    class="card-img"
                    :src="getFreelancerCompany.companyAvator"
                  ></v-img>
                </v-avatar>
              </v-col>

              <v-col offset="1" cols="10" class="background">
                <v-row>
                  <v-col>
                    <div
                      class="text-center mb-3 title text-secundario font-weight-bold"
                    >
                      <span>@</span>{{ getFreelancerCompany.companyName }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col lg="7" cols="md" class="pb-2">
          <v-card-title class="primary--text">
            <span class="overline">My Companies</span>
          </v-card-title>

          <v-card v-if="getMyCompanies.length > 0" class="mb-4 pa-5">
            <!-- display companies if available -->
            <v-row
              class="px-2"
              lg="12"
              v-for="company in getMyCompanies"
              :key="company.id"
            >
              <v-col class="pt-3">
                <v-row class="pt-3" @click="submitActiveCompany(company)">
                  <v-list-item-avatar>
                    <v-img alt="" :src="company.companyAvator" />
                  </v-list-item-avatar>
                  <v-list-item-content class="text-truncate">
                    {{ company.companyName }}
                  </v-list-item-content>

                  <div class="pt-4">
                    <v-btn icon small>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </v-row>

                <v-row>
                  <v-col lg="12" cols="md" class="pb-2">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <v-card v-else class="mb-4 pa-5">
            <CompanyListLoader></CompanyListLoader>
            <CompanyListLoader></CompanyListLoader>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
const imageLink = "../../../assets/logo.png";
import ActiveCompanyLoader from "@/components/loaders/ActiveCompanyLoader";
import CompanyListLoader from "@/components/loaders/CompanyListLoader";

export default {
  template: "#generalSettings",

  components: {
    ActiveCompanyLoader,
    CompanyListLoader,
  },

  data: () => ({}),

  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    ...mapState([
      "myProfile",
      "myCompanies",
      "activeCompany",
      "freelancerCompany",
    ]),
    ...mapGetters([
      "getMyProfile",
      "getMyCompanies",
      "getActiveCompany",
      "getFreelancerCompany",
    ]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$store.dispatch("fetchMyProfile");
        this.$store.dispatch("fetchMyCompanies");
        this.$store.dispatch("fetchMyCompany");
      }
    },

    getActiveCompany(val) {
      this.$store.dispatch("fetchMyProfile");
      this.$store.dispatch("fetchMyCompanies");
      this.$store.dispatch("fetchMyCompany");
      this.$forceUpdate();
    },
  },

  beforeCreate() {
    this.$store.dispatch("fetchMyProfile");
    this.$store.dispatch("fetchMyCompanies");
    this.$store.dispatch("fetchMyCompany");
  },

  methods: {
    submitActiveCompany(company) {
      this.$store.dispatch("submitActiveCompany", company);
      this.$router.push({
        path: "/",
        query: {},
      });
    },

    registerCompany() {
      this.$router.push({
        path: "/createCompany",
        query: {},
      });
    },
  },
};
</script>
